import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
const { REACT_APP_SHAKESPEARE_API_URL } = process.env;

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const url = `${REACT_APP_SHAKESPEARE_API_URL}/login`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('submitting');
    try {
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });
      const res = await response.json();
      console.log(res);

      if (res.status === 200) {
        setUsername('');
        setPassword('');
        setError('');
        navigate('/');
      } else {
        setError(
          `Status: ${res.status}, message: ${res.message} - ${res.data?.error}`
        );
      }
    } catch (err) {
      console.log('catch error');
      if (!err?.response) {
        setError('no server response');
      } else {
        setError('registeration failed');
      }
    }
  };

  return (
    <div>
      <form id="login-container" onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="form-error">{error}</div>
        </div>
        <div className="form-group">
          <label htmlFor="username" className="form-label">
            USERNAME:
          </label>
          <input
            type="text"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="login-form-control"
            placeholder="Enter username"
            id="username"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="form-label">
            PASSWORD:
          </label>
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-form-control"
            placeholder="Enter password"
            id="password"
            required
          />
        </div>
        <input
          className="form-button submit-button"
          type="submit"
          value="Submit"
          disabled={!username || !password ? true : false}
        />
      </form>
    </div>
  );
}
