import "./SearchQuote.css";

export default function SearchQuote({formData, setFormData, handleSubmit}) {
  return (
    <div>
      <form id="quote-search-container" onSubmit={handleSubmit}>
        <div className="form-inner">
          <div className="form-comment">Enter a search tag (e.g. courage)</div>
          <input
            type="text"
            name="search"
            className="form-control"
            placeholder="courage"
            onChange={(e) => setFormData({...formData, tag: e.target.value})}
            value={formData.tag}
            required
          />
          </div>
          <input className="form-button search-button" type="submit" value="Find Quote" />
      </form>
    </div>
  )
}