import { Route, Routes } from 'react-router-dom';
import { useState } from 'react';
import Login from './components/Login';
import Navbar from './components/Navbar';
import QuoteList from './components/QuoteList';
import SearchQuote from './components/SearchQuote';
import './App.css';

function App() {
  const [searchVal, setSearchVal] = useState({ search: '' });
  const [formData, setFormData] = useState({ tag: '' });
  const [loggedIn, setLoggedIn] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchVal({ ...searchVal, search: e.target.search.value });
  };

  return (
    <div className="App">
      <Navbar
        formData={formData}
        setFormData={setFormData}
        searchVal={searchVal}
        setSearchVal={setSearchVal}
      />
      <main>
        <h1 className="title">Shakespeare Sunday</h1>
        <h2 className="sub-title">
          A collection of Shakespeare quotes for{' '}
          <a
            href="https://twitter.com/hashtag/ShakespeareSunday?src=hash"
            target="_blank"
            rel="noopener noreferrer"
            alt="Shakespeare Sunday hashtag on Twitter"
          >
            #ShakespeareSunday
          </a>
        </h2>
      </main>
      <div className="App-header">
        <Routes>
          <Route
            path="/"
            element={
              <QuoteList
                hide={false}
                searchVal={searchVal.search}
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
              />
            }
          />
          <Route
            path="/login"
            element={<Login setLoggedIn={setLoggedIn} />}
          />
          <Route
            path="/search"
            element={
              <>
                <SearchQuote
                  formData={formData}
                  setFormData={setFormData}
                  handleSubmit={handleSubmit}
                />
                <QuoteList
                  hide={true}
                  searchVal={searchVal.search}
                  loggedIn={loggedIn}
                  setLoggedIn={setLoggedIn}
                />
              </>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
