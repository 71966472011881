// Navbar.js
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

export default function Navbar({
  formData,
  setFormData,
  searchVal,
  setSearchVal,
}) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const displayAll = () => {
    setFormData({ ...formData, tag: '' });
    setSearchVal({ ...searchVal, search: '' });
    if (isNavExpanded) {
      setIsNavExpanded(!isNavExpanded);
    }
  };

  const search = () => {
    if (isNavExpanded) {
      setIsNavExpanded(!isNavExpanded);
    }
  };

  const login = () => {
    setFormData({ ...formData, tag: '' });
    setSearchVal({ ...searchVal, search: '' });
    if (isNavExpanded) {
      setIsNavExpanded(!isNavExpanded);
    }
  };

  return (
    <nav className="navigation">
      <Link to="/" className="brand-name">
        Shakespeare
      </Link>

      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </button>
      <div
        className={
          isNavExpanded
            ? 'navigation-menu expanded'
            : 'navigation-menu'
        }
      >
        <ul>
          <li>
            <Link to="/">
              <span onClick={displayAll}>Display All</span>
            </Link>
          </li>
          <li>
            <Link to="/search">
              <span onClick={search}>Search Tag</span>
            </Link>
          </li>
          <li>
            <Link to="/login">
              <span onClick={login}>Log In</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
