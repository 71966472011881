import { tweetUrl } from '../utils/tweet';
import twitterLogo from '../assets/Twitter_Logo_WhiteOnImage.png';
import "./Quote.css";

export default function Quote(props) {
    const act = props.act && `(Act ${props.act}`;
    const scene = props.scene && ` Scene ${props.scene})`;

    const displayTags = (tags) => {
      const tagButtons = tags.map((tag, index) => {
        return (
          <span key={index} className="tag">
            {decodeURI(tag)}
          </span>
        );
      });
      return tagButtons;
    }

    return (
        <li className="quote-box">
          <span className="quote quote-span">&quot;{props.quote}&quot;</span>
          <span className="work quote-span">- {props.work}</span>
          <span className="act quote-span">{act}</span>
          <span className="scene quote-span">{scene}</span>
          <span className="tags quote-span">Tags: {displayTags(props.tags)}</span>
          <div className="controls">
          <span className="tweet">
            <a
              className="tweet-button"
              href={tweetUrl(props.quote, props.work, props.act, props.scene)}
            >
              <img width="25px" height="25px" src={twitterLogo} alt="twitter logo" />
            </a>
          </span>
          </div>
        </li>
    );
}