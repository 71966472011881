import { useEffect, useState } from 'react';
import Loading from './Loading';
import Quote from './Quote';
import './QuoteList.css';
const { REACT_APP_SHAKESPEARE_API_URL } = process.env;

export default function QuoteList({ hide, searchVal }) {
  const [quotes, setQuotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [displayResults, setDisplayResults] = useState(false);
  const [hideList, setHideList] = useState(hide);
  console.log(hideList);
  const rndInt = Math.floor(Math.random() * 32) + 1;
  let url = searchVal
    ? `${REACT_APP_SHAKESPEARE_API_URL}/quotes/${searchVal}/1`
    : `${REACT_APP_SHAKESPEARE_API_URL}/quotes/${rndInt}`;

  useEffect(() => {
    if (hideList) {
      setDisplayResults(false);
      setHideList(false);
    } else {
      setIsLoading(true);
      setDisplayResults(true);
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data) {
            // use the data returned to set the setQuotes state
            setQuotes(data);
          }
          // make sure to add the empty dependency array, or you will end
          // up in an infinite loop
        })
        .catch((error) => console.log(error))
        .finally(() => setIsLoading(false));
    }

    // eslint-disable-next-line
  }, [searchVal]);

  return (
    <div id="quote-list">
      {isLoading ? (
        <div className="loading">
          <Loading />
        </div>
      ) : !displayResults ? (
        <div></div>
      ) : quotes.data && quotes.data.data ? (
        quotes.data.data.map((quote) => (
          <ul key={quote.id.toString()}>
            <Quote
              id={quote.id}
              quote={quote.quote}
              work={quote.work}
              act={quote.act}
              scene={quote.scene}
              tags={quote.tags}
            />
          </ul>
        ))
      ) : (
        <div className="no-results">
          <strong>No results for the tag "{searchVal}"</strong>
        </div>
      )}
    </div>
  );
}
